export const CREATE_COLLECTION = `
  import NFTTickets from 0xNFTTickets

  transaction {
    prepare(acct: AuthAccount) {
      acct.save(<- NFTTickets.createEmptyCollection(), to: /storage/TixNFTCollection)
      acct.link<&NFTTickets.Collection{NFTTickets.CollectionPublic}>(/public/TixNFTCollection, target: /storage/TixNFTCollection)
    }

    execute {
      log("Created empty collection and added capability.")
    }
  }
`;
