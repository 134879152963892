import S from "./styled";

function Hdg() {
  return (
    <S.Container>
      <div>2022 Ticket Management Portal</div>
    </S.Container>
  );
}

export default Hdg;
