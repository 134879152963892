import styled from "styled-components";
import { Container } from "../../styles/styled";

let S = {};

S.Container = styled(Container)`
  & li {
    font-size: 1.1rem;
    padding-bottom: 20px;
  }

  & small {
    display: block;
    margin-top: 5px;
    font-size: 13px;
    line-height: 20px;
  }

  & .notice-container {
    padding: 0 15px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  & .grid {
    padding: 0 15px 15px 15px;
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;

    &.one {
      grid-template-columns: 1fr;
    }
  }
`;

S.LogoContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  & > a {
    width: 80px;
  }

  & > a:last-child {
    width: 160px;
  }
`;

export default S;
