import styled from "styled-components";

let S = {};

S.Container = styled.div`
  width: 100%;
  height: 45px;
  padding: 0 10px 0 15px;
  background-color: var(--bggrey);
  border-top: 1px solid var(--border);
  display: flex;
  align-items: center;
  font-weight: 500;
`;

export default S;
