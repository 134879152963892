import S from "./styled";

import Hdg from "../../shared/Hdg";
import Notice from "../../shared/Notice";
import Button from "../../shared/Button";

function Home() {
  return (
    <>
      <Hdg />

      <S.Content>
        <p className="lead">
          The ticketing system for this year’s event is paperless and uses
          blockchain technology to insure the security and validity of your
          entry ticket.
        </p>

        <ul>
          <li>
            Your tickets need to be minted as NFTs and placed in your wallet
            account in order to use for gate entry. If you would like an
            overview of this process, please visit our instructions page.
          </li>

          <li>
            If you need to mint your entry tickets, but maybe have misplaced
            your receipt email, use the the locate order button below and you
            can use your email address and the last 4 digits of the card used
            for the ticket purchase to locate your order.
          </li>

          <li>
            If someone has sent you tickets, use the button below and we will
            attempt to resend the ticket transfer invite using your email
            address.
          </li>

          <li>
            If you have already minted your tickets and are ready to redeem for
            gate entry, use the view minted tickets button below.
          </li>
        </ul>

        <div className="notice-container">
          <Notice text="Please note that it is helpful to access this portal using your mobile device, as you will need to present your ticket code digitally for entry into the event." />
        </div>

        <div className="grid">
          <Button
            label="View Instructions"
            click="/p/instructions"
            link={true}
          />

          <Button
            label="Locate Ticket Purchase"
            click="/p/locate"
            link={true}
          />

          <Button
            label="Retrieve Sent Tickets"
            click="/p/resendxfer"
            link={true}
          />

          <Button label="View Minted Tickets" click="/p/nfts" link={true} />
        </div>
      </S.Content>
    </>
  );
}

export default Home;
