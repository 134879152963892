import { useState, createRef, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { API_URL, RECAPTCHA_KEY } from "../../utils/index";
import S from "./styled";

import InputText from "../../shared/InputText";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";
import BackButton from "../../shared/BackButton";

function PubResendXfer() {
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});
  const [errors, setErrors] = useState([]);

  const recaptchaRef = createRef();
  const formElement = useRef();
  const navigate = useNavigate();

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMsg({
      type: "working",
      text: "",
    });

    let recaptcha = names.recaptcha
      ? names.recaptcha
      : await recaptchaRef.current.executeAsync();

    const url = new URL(`${API_URL}/resendXfer`);
    const formData = new FormData(formElement.current);
    formData.append("recaptcha", recaptcha);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        navigate("/alert", {
          state: { data: { type: "success", text: json.text }, url: "/" },
        });
      } else {
        if (json.fields[0] === "recaptcha") {
          handleUpdate("recaptcha", "");
          recaptchaRef.current.reset();
        } else {
          handleUpdate("recaptcha", recaptcha);
        }
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      <S.Container>
        <h3>Resend Ticket Transfer Invite</h3>
        <p>
          If someone has sent you tickets, but you have misplaced your invite
          email, please use the form below and we will attempt to resend you the
          ticket transfer invite using your name and email address.
        </p>
      </S.Container>

      <S.FormContainer>
        <form
          method="post"
          action="/"
          onSubmit={handleSubmit}
          ref={formElement}
        >
          <S.One style={{ marginBottom: "20px" }}>
            <InputText
              name="name"
              label="Your Name"
              req={true}
              error={errors.includes("name") ? true : false}
              autocomplete="name"
            />
          </S.One>

          <S.One style={{ marginBottom: "25px" }}>
            <InputText
              name="email"
              label="Email Address"
              req={true}
              error={errors.includes("email") ? true : false}
              autocomplete="email"
            />
          </S.One>

          <S.One>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={RECAPTCHA_KEY}
              badge="inline"
            />
          </S.One>

          <div className="msg-submit">
            {msg.type && <Msg data={msg} />}
            {msg.type === "working" ? null : (
              <Submit name="Resend Transfer Invite" />
            )}
          </div>

          <BackButton label="Back to Main Menu" click="/" />
        </form>
      </S.FormContainer>
    </>
  );
}

export default PubResendXfer;
