export const MINT_NFT = `
  import NFTTickets from 0xNFTTickets

  transaction(title: String, day: String, code: String) {
    prepare(acct: AuthAccount) {
        let collectionRef = acct.borrow<&NFTTickets.Collection>(from: /storage/TixNFTCollection)
            ?? panic("Could not borrow from /storage.")
        collectionRef.deposit(token: <- NFTTickets.MintNFT(title: title, day: day, code: code))
    }

    execute {
      log("Minted NFT and deposited to /storage/TixNFTCollection.")
    }
  }
`;
