import { useState, Children, cloneElement } from "react";
import S from "./styled";
import { faCircle as circle } from "@fortawesome/pro-light-svg-icons";
import { faCircle as circleFilled } from "@fortawesome/free-solid-svg-icons";

import Button from "../../shared/Button";

export const CarouselItem = ({ children, width }) => {
  return (
    <S.CarouselItemContainer width={width}>{children}</S.CarouselItemContainer>
  );
};

function Carousel({ children, num }) {
  const [activeIndex, setActiveIndex] = useState(0);

  /*const handleIndex = (newIndex) => {
    const count = Children.count(children);

    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= count) {
      newIndex = count - 1;
    }

    setActiveIndex(newIndex);
  };*/

  return (
    <>
      <S.CarouselContainer>
        <S.CarouselInner transform={`translateX(-${activeIndex * 100}%)`}>
          {Children.map(children, (child, index) => {
            return cloneElement(child, { width: "100%" });
          })}
        </S.CarouselInner>
      </S.CarouselContainer>

      <S.CirclesContainer>
        {num > 1 && (
          <div>
            {[...Array(num).keys()].map((index) => {
              return (
                <Button
                  naked={true}
                  icon={activeIndex === index ? circleFilled : circle}
                  click={() => setActiveIndex(index)}
                  size="xs"
                />
              );
            })}
          </div>
        )}
      </S.CirclesContainer>
    </>
  );
}

export default Carousel;
