import { getValFromLS } from "../../utils";
import BloctoLogo from "../../assets/blocto-logo.png";
import FlowLogo from "../../assets/flow-logo.svg";
import S from "./styled";

import Notice from "../../shared/Notice";
import Button from "../../shared/Button";

function PubInstructions() {
  return (
    <>
      <S.Container>
        <h3>Overview of NFT Tickets</h3>

        <p>
          The ticketing system for this year’s event is paperless and uses
          blockchain technology to insure the security and validity of your
          entry ticket.
        </p>

        <div className="notice-container">
          <Notice text="Please mint your tickets using your mobile device, as you will need to present your tickets digitally for entry into the event." />
        </div>

        <ol>
          <li>
            Connect Blocto Wallet on the Flow Blockchain
            <br />
            <small>
              With just your email address, you will be able to create a Blocto
              account on the Flow blockchain which is needed to securely store
              your NFTs. Click the logos below if you are interested in learning
              more about the protocol.
            </small>
            <S.LogoContainer>
              <a href="https://portto.com" target="_blank" rel="noreferrer">
                <img src={BloctoLogo} alt="Blocto Logo" />
              </a>
              <a href="https://www.onflow.org" target="_blank" rel="noreferrer">
                <img src={FlowLogo} alt="Flow Logo" />
              </a>
            </S.LogoContainer>
          </li>

          <li>
            Create an Empty Collection
            <br />
            <small>
              The next step is to create an empty collection in your account.
              Simply click the create collection button on the My Tickets page
              and wait for the transaction to complete.
            </small>
          </li>

          <li>
            Mint your Tickets as NFTs
            <br />
            <small>
              Click the mint button for each ticket you have purchased. The
              minting process will take a few seconds, but once the transaction
              is complete, your ticket is now a secure NFT that is only
              available in your wallet.
            </small>
          </li>

          <li>
            Tournament Entry
            <br />
            <small>
              Using your mobile device, simply show your minted ticket at the
              entrance gate and they will be scanned for grounds entry.
            </small>
          </li>
        </ol>

        <div className={getValFromLS("str", true) ? "grid" : "grid one"}>
          <Button label="Main Menu" click="/" link={true} />

          {getValFromLS("str", true) && (
            <Button
              label="My Tickets"
              click={`/p/locate/${getValFromLS("str", true)}`}
              link={true}
            />
          )}
        </div>
      </S.Container>
    </>
  );
}

export default PubInstructions;
