import styled from "styled-components";

const S = {};

S.LogoContainer = styled.div`
  padding-top: 2.5px;
  width: 100%;
  height: 125px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & img {
    width: 250px;
  }
`;

export default S;
