import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faCircleNotch,
  faRightFromBracket,
} from "@fortawesome/pro-light-svg-icons";

import Button from "../Button";

function AdminStatus({ user, working, signout }) {
  return (
    <S.Container className={user?.email ? "" : "working"}>
      <div>
        <FontAwesomeIcon icon={faAddressCard} size="lg" />
        {user?.email ? <span>{user.email}</span> : <span>Not Signed In</span>}
      </div>

      {working && <FontAwesomeIcon icon={faCircleNotch} size="lg" spin />}
      {user.email && (
        <Button
          label=""
          click={signout}
          naked={true}
          icon={faRightFromBracket}
        />
      )}
    </S.Container>
  );
}

export default AdminStatus;
