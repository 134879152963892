import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import S from "./styled";

function Button({
  label,
  click,
  link = false,
  naked = false,
  icon = null,
  size = "lg",
}) {
  return (
    <>
      {naked ? (
        <S.Button type="button" onClick={click} className="naked">
          <FontAwesomeIcon icon={icon} size={size} />
        </S.Button>
      ) : link ? (
        <S.Link to={click}>
          <span>{label}</span>
        </S.Link>
      ) : (
        <S.Button type="button" onClick={click}>
          <span>{label}</span>
        </S.Button>
      )}
    </>
  );
}

export default Button;
