import styled from "styled-components";
import { Container, FormContainer, Two } from "../../styles/styled";

let S = {};

S.Container = styled(Container)``;

S.ButtonsContainer = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: ${(props) => (props.one ? "1fr" : "  1fr 1fr")};
  grid-gap: 25px;

  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
`;

S.FormContainer = styled(FormContainer)``;
S.Two = styled(Two)``;

export default S;
