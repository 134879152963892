import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { API_URL, getValFromLS } from "../../utils/index";
import { GET_NFT_IDS } from "../../cadence/get-nft-ids.script";
import { GET_NFT_META } from "../../cadence/get-nft-meta.script";
import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faCircleX,
  faCircleNotch,
} from "@fortawesome/pro-light-svg-icons";

import Button from "../../shared/Button";

import Carousel, { CarouselItem } from "./Carousel";
import Ticket from "./Ticket";

function PubNfts({ user, connect }) {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      const response = await fcl
        .send([
          fcl.script(GET_NFT_IDS),
          fcl.args([fcl.arg(user.addr, t.Address)]),
        ])
        .then(fcl.decode);

      if (response.length > 0) {
        getNftMetaData(response);
      } else {
        setLoading(false);
      }
    };

    if (user.addr) {
      init();
    } else if (!user.loggedIn) {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getNftMetaData = async (ids) => {
    setLoading(true);
    let codes = [];
    for (const id of ids) {
      const code = await fcl
        .send([
          fcl.script(GET_NFT_META),
          fcl.args([fcl.arg(user.addr, t.Address), fcl.arg(id, t.UInt64)]),
        ])
        .then(fcl.decode);

      codes.push({
        nftId: id,
        code,
      });
    }

    getMintedTickets(codes);
  };

  const getMintedTickets = async (array) => {
    setLoading(true);
    const url = new URL(`${API_URL}/mintedTicketsInit`);
    const params = { q: JSON.stringify(array), addr: user.addr };
    url.search = new URLSearchParams(params);

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setNfts(json.items);
        setLoading(false);
      } else {
        navigate("alert", {
          state: {
            data: { type: "error", text: "An error has occurred." },
            url: "/",
          },
        });
      }
    } catch (error) {
      navigate("alert", {
        state: {
          data: { type: "error", text: "An error has occurred." },
          url: "/",
        },
      });
    }
  };

  return (
    <>
      {loading ? (
        <S.ConnectContainer height="253px" color="var(--working)">
          <FontAwesomeIcon icon={faCircleNotch} size="2x" spin={true} />
          <span>Checking wallet for your NFTs...</span>
        </S.ConnectContainer>
      ) : user?.addr && nfts.length > 0 ? (
        <S.Container>
          <h3>My Minted Ticket NFTs</h3>
          <p>
            These are your tickets that have been minted as NFTs and are
            available for presenting as entry at this year’s event.
          </p>

          <Carousel num={nfts.length}>
            {nfts.map((item) => {
              return (
                <CarouselItem key={item.nftId}>
                  <Ticket data={item} addr={user.addr} />
                </CarouselItem>
              );
            })}
          </Carousel>
        </S.Container>
      ) : user?.addr ? (
        <S.ConnectContainer height="253px" color="var(--error)">
          <FontAwesomeIcon icon={faCircleX} size="2x" />
          <span>There are no NFTs found in this wallet.</span>
        </S.ConnectContainer>
      ) : (
        <S.ConnectContainer height="253px" color="var(--notice)">
          <FontAwesomeIcon icon={faCircleExclamation} size="2x" />
          <span>Please connect your wallet to view your minted tickets.</span>
          <Button label="Connect Wallet" click={connect} />
        </S.ConnectContainer>
      )}

      <S.Grid one={getValFromLS("str", true) ? false : true}>
        <Button label="Main Menu" click="/" link={true} />

        {getValFromLS("str", true) && (
          <Button
            label="My Tickets"
            click={`/p/locate/${getValFromLS("str", true)}`}
            link={true}
          />
        )}
      </S.Grid>
    </>
  );
}

export default PubNfts;
