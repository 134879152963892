import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/pro-light-svg-icons";
import S from "./styled";

function InputCheckbox({ label, name, checked, click }) {
  return (
    <S.Button
      type="button"
      onClick={() => click(name, !checked)}
      className="naked"
    >
      <div>
        <FontAwesomeIcon icon={checked ? faSquareCheck : faSquare} size="2x" />
        <span>{label}</span>
      </div>
    </S.Button>
  );
}

export default InputCheckbox;
