import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import * as fcl from "@onflow/fcl";

import WalletStatus from "./shared/WalletStatus";

import PubLocate from "./pages/PubLocate";
import PubLocating from "./pages/PubLocating";
import PubPurchased from "./pages/PubPurchased";
import PubMint from "./pages/PubMint";
import PubXfer from "./pages/PubXfer";
import PubXferring from "./pages/PubXferring";
import PubResendXfer from "./pages/PubResendXfer";
import PubReceived from "./pages/PubReceived";
import PubNfts from "./pages/PubNfts";
import PubInstructions from "./pages/PubInstructions";

/*  purchased ticket statuses
  1 - ticket minted as NFT and in wallet
  2 - ticket pending
  0 - ticket xfered to someone else
*/

function Pub() {
  const [user, setUser] = useState({});
  const [working, setWorking] = useState(false);
  const [order, setOrder] = useState({});
  const [ticket, setTicket] = useState({});

  useEffect(() => {
    fcl.currentUser.subscribe(setUser);
  }, []);

  const handleConnectWallet = () => {
    setWorking(true);
    fcl
      .authenticate()
      .then(setWorking(false))
      .catch((error) => setWorking(false));
  };

  const handleDisconnectWallet = () => {
    setWorking(true);
    fcl.unauthenticate();
    setWorking(false);
  };

  return (
    <>
      <WalletStatus
        user={user}
        working={working}
        disconnect={handleDisconnectWallet}
      />
      <Routes>
        <Route path="/locate" element={<PubLocate />} />
        <Route
          path="/locate/:str"
          element={<PubLocating setOrder={setOrder} />}
        />
        <Route
          path="/purchased"
          element={
            <PubPurchased
              order={order}
              user={user}
              connect={handleConnectWallet}
            />
          }
        />
        <Route path="/mint" element={<PubMint user={user} />} />
        <Route path="/xfer" element={<PubXfer setOrder={setOrder} />} />
        <Route
          path="/xfer/:str"
          element={<PubXferring setTicket={setTicket} />}
        />
        <Route path="/resendxfer" element={<PubResendXfer />} />
        <Route
          path="/received"
          element={
            <PubReceived
              ticket={ticket}
              user={user}
              connect={handleConnectWallet}
            />
          }
        />
        <Route
          path="/nfts"
          element={<PubNfts user={user} connect={handleConnectWallet} />}
        />
        <Route path="/instructions" element={<PubInstructions />} />
      </Routes>
    </>
  );
}

export default Pub;
