import styled from "styled-components";

let S = {};

S.Container = styled.div`
  margin-top: 15px;
  color: #fff;

  & > h3 {
    font-size: 1.25rem;
    line-height: 26px;
    text-align: center;
    padding: 0 15px 15px 15px;
    text-transform: uppercase;
  }

  & > p {
    padding: 0 15px 15px 15px;
  }

  & > ul {
    margin: 0;
    margin-left: 30px;
    padding: 0;
    font-size: 0.95rem;
    padding: 0 15px;
  }

  & > ul > li {
    line-height: 22px;
  }
`;

S.TxStatusContainer = styled.div`
  width: 100%;
  padding: 0px 25px 15px 25px;

  &.hidden {
    display: none;
  }
`;

export default S;
