import styled from "styled-components";

let S = {};

S.Container = styled.div`
  margin-top: 5px;
  width: 100%;

  & > button {
    width: 100%;
    display: inline-block;
    border: none;
    color: #fff;
    font-weight: 500;
    background-color: var(--bglightblue);
    border-radius: 4px;
    border: 2px solid #fff;
    padding: 10px 15px 9.5px 15px;
    margin: 0;
    text-decoration: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    transition: all 0.3s;
    text-transform: uppercase;

    &:hover {
      background-color: var(--bgblue);
    }
  }
`;

export default S;
