import { Link } from "react-router-dom";
import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/pro-light-svg-icons";

function BackButton({ label, click }) {
  return (
    <S.Container>
      <Link to={click}>
        <FontAwesomeIcon icon={faChevronCircleLeft} size="lg" />
        <span>{label}</span>
      </Link>
    </S.Container>
  );
}

export default BackButton;
