import { useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/index";
import S from "./styled";

import Button from "../../shared/Button";
import Notice from "../../shared/Notice";

import StatusMsg from "./StatusMsg";

function Table({ data, user, collection }) {
  const navigate = useNavigate();

  const handleMint = async (item) => {
    navigate("/p/mint", { state: { item } });
  };

  const handleXfer = (item) => {
    navigate("/p/xfer", { state: { item } });
  };

  return (
    <S.Table>
      <thead>
        <tr>
          <th style={{ fontSize: "1.1rem" }}>{`Order ${data.orderId}`}</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td
            style={{ color: "#fff", paddingBottom: "0" }}
          >{`Placed ${data.date} by ${data.name}`}</td>
        </tr>
        <tr>
          <td style={{ paddingTop: "10px" }}>
            <S.Grid>
              {data.items.map((item) => {
                return (
                  <div key={item.code}>
                    <img
                      src={`${API_URL}/ticketImg?id=${item.category}`}
                      alt={item.title}
                    />

                    <div>
                      <p>
                        {item.title}
                        <br />
                        <small>{item.day}</small>
                      </p>

                      {user.addr && collection && item.status === 1 ? (
                        <Notice
                          type="success"
                          text="This ticket is minted and ready to use for gate entry."
                        />
                      ) : user.addr &&
                        collection &&
                        (item.status === 3 || item.status === 0) ? (
                        <Notice
                          type="notice"
                          text={`This ticket has been transferred to ${item.xferEmail}.`}
                        />
                      ) : user.addr && collection ? (
                        <S.ButtonsContainer2>
                          <Button
                            label="Mint Ticket to My Account"
                            click={() => handleMint(item)}
                          />
                          <Button
                            label="Transfer Ticket to Someone Else"
                            click={() => handleXfer(item)}
                          />
                        </S.ButtonsContainer2>
                      ) : !user.addr ? (
                        <StatusMsg text="Please connect wallet" />
                      ) : (
                        <StatusMsg text="Please create collection" />
                      )}
                    </div>
                  </div>
                );
              })}
            </S.Grid>
          </td>
        </tr>
      </tbody>
    </S.Table>
  );
}

export default Table;
