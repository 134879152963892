import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faCircleXmark,
  faCircleCheck,
  faTriangleExclamation,
} from "@fortawesome/pro-light-svg-icons";

function Msg({ data }) {
  let icon =
    data.type === "error"
      ? faCircleXmark
      : data.type === "success"
      ? faCircleCheck
      : faTriangleExclamation;

  return data.type === "working" ? (
    <FontAwesomeIcon
      icon={faCircleNotch}
      size="lg"
      spin
      style={{ color: "var(--working)", fontSize: "1.3rem" }}
    />
  ) : (
    <S.Container>
      <FontAwesomeIcon
        icon={icon}
        size="lg"
        style={{ color: `var(--${data.type})`, fontSize: "1.3rem" }}
      />
      <span style={{ color: `var(--${data.type})` }}>{data.text}</span>
    </S.Container>
  );
}

export default Msg;
