import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as Logo } from "../../assets/wwt-championship-logo.svg";
import image from "../../assets/ticket-header.jpg";
import { ReactComponent as ApplePass } from "../../assets/apple-pass.svg";

function Ticket({ data, addr }) {
  return (
    <S.TicketContainer>
      <FontAwesomeIcon
        icon={faCircle}
        size="4x"
        id="half-circle"
        style={{ color: "var(--blue)" }}
      />

      <div id="logo-container">
        <Logo />
      </div>

      <div id="day-container">
        <span>{data.day}</span>
      </div>

      <div id="img-container">
        <img src={image} alt="WWT Championship at Mayakoba" />
      </div>

      <div id="title-container">
        <span>{data.title}</span>
        <small>El Camaleón Golf Club at Mayakoba</small>
      </div>

      <div id="qr-container">
        <img src={data.qr} alt="QR Code" />
      </div>

      <div id="id-container">
        <div>
          <small>Ticket Code</small>
          <span>{data.code}</span>
        </div>

        <div>
          <small>{`NFT #${data.nftId}`}</small>
        </div>

        <div>
          <a
            href={`https://wwt-championship-api.onrender.com/pass?code=${data.code}&addr=${addr}`}
            title="Create Apple Pass"
            target="_blank"
            rel="noreferrer"
          >
            <ApplePass />
          </a>
        </div>
      </div>
    </S.TicketContainer>
  );
}

export default Ticket;
