import { config } from "@onflow/fcl";
//import { send as grpcSend } from "@onflow/transport-grpc";
//  "sdk.transport": grpcSend,

config()
  .put("accessNode.api", "https://access-testnet.onflow.org")
  .put("discovery.wallet", "https://fcl-discovery.onflow.org/testnet/authn")
  .put(
    "app.detail.icon",
    "https://wwtchampionshipatmayakoba.com/tickets/api/assets/top-nav-logo.png"
  )
  .put("0xNFTTickets", "0x0581ba986e88f0b3");

/*
config({
  "accessNode.api": "http://localhost:8080",
  "discovery.wallet": "http://localhost:8701/fcl/authn",
  "flow.network": "local",
  "app.detail.title": "Emulator Wallet",
  "app.detail.icon": "https://i.imgur.com/r23Zhvu.png",
  "0xNFTTickets": "0xf8d6e0586b0a20c7",
});
*/
