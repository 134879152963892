import { useLocation } from "react-router-dom";
import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faCircleCheck } from "@fortawesome/pro-light-svg-icons";

import Hdg from "../../shared/Hdg";
import Button from "../../shared/Button";

function Alert() {
  const location = useLocation();

  const color =
    location.state?.data?.type === "error"
      ? "var(--error)"
      : location.state?.data?.type === "success"
      ? "var(--successLighter)"
      : "#fff";

  const icon =
    location.state?.data?.type === "success" ? faCircleCheck : faCircleXmark;

  const text = location.state?.data?.text
    ? location.state.data.text
    : "An unknown error has occurred.";

  const url = location.state?.url ? location.state?.url : "/";

  return (
    <>
      <Hdg />

      <S.Container height="175px" color={color}>
        <FontAwesomeIcon icon={icon} size="2x" />
        <span>{text}</span>

        <Button label="Continue" click={url} link={true} />
      </S.Container>
    </>
  );
}

export default Alert;
