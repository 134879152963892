import S from "./styled";

function Submit({ name }) {
  return (
    <S.Container>
      <button type="submit">
        <span>{name}</span>
      </button>
    </S.Container>
  );
}

export default Submit;
