import styled from "styled-components";

export const Container = styled.div`
  margin-top: 15px;
  color: #fff;

  & > h3 {
    font-size: 1.25rem;
    line-height: 26px;
    text-align: center;
    padding: 0 15px 15px 15px;
    text-transform: uppercase;
  }

  & > p {
    padding: 0 15px 15px 15px;
  }

  & > ul,
  & > ol {
    margin: 0;
    margin-left: 30px;
    padding: 0;
    font-size: 0.95rem;
    padding: 0 15px;
  }

  & > ul > li,
  & > ol > li {
    line-height: 22px;
  }
`;

export const FormContainer = styled.div`
  margin-top: 5px;
  width: 100%;
  padding: 15px 25px;

  &.hidden {
    display: none;
  }

  & > form {
    color: #fff;
    padding: 15px 15px 25px 15px;
    width: 100%;
    border-radius: 0;
    color: inherit;
    font-size: 0.9rem;
    border: 1px solid #fff;

    & > div {
      padding-left: 10px;
      padding-right: 8.5px;
    }

    & > div.msg-submit {
      width: 100%;
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const One = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 0;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &.checkbox {
    margin-top: 15px;
  }
`;

export const Two = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;

  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-gap: 0;

    & div.empty {
      display: none;
    }
  }

  & > div {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  }
`;
