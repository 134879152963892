import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { MINT_NFT } from "../../cadence/mint-nft.tx";
import { API_URL, getValFromLS } from "../../utils/index";
import S from "./styled";

import TxProgress from "../../shared/TxProgress";

function PubMint({ user }) {
  const [txId, setTxId] = useState("");
  const [txStatus, setTxStatus] = useState(10);
  //const [txStatusCode, setTxStatusCode] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const init = async (title, day, code) => {
      setTxStatus(9);

      const transactionId = await fcl
        .send([
          fcl.transaction(MINT_NFT),
          fcl.args([
            fcl.arg(title, t.String),
            fcl.arg(day, t.String),
            fcl.arg(code, t.String),
          ]),
          fcl.proposer(fcl.authz),
          fcl.payer(fcl.authz),
          fcl.authorizations([fcl.authz]),
          fcl.limit(9999),
        ])
        .then(fcl.decode, (rejected) => setTxStatus(10));

      if (transactionId) {
        setTxId(transactionId);
        fcl.tx(transactionId).subscribe((res) => {
          setTxStatus(res.status);
          //setTxStatusCode(res.statusCode);
          //console.log(res);
        });
      }
    };

    if (location?.state?.item?.code) {
      console.log(location.state.item);
      init(
        location.state.item.title,
        location.state.item.day,
        location.state.item.code
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const minted = async (code) => {
      const url = new URL(`${API_URL}/minted`);
      const params = { code, addr: user.addr };
      url.search = new URLSearchParams(params);

      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
        });
        const json = await response.json();
        if (json && json.resp === 1) {
          if (getValFromLS("str", true)) {
            navigate(`/p/locate/${getValFromLS("str", true)}`);
          } else {
            navigate(`/p/nfts`);
          }
        } else {
          navigate("/alert", {
            state: {
              data: { type: "error", text: "An error has occurred." },
              url: "/",
            },
          });
        }
      } catch (error) {
        navigate("/alert", {
          state: {
            data: { type: "error", text: "An error has occurred." },
            url: "/",
          },
        });
      }
    };

    if (txStatus === 4) {
      minted(location.state.item.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txStatus]);

  return (
    <>
      <S.Container>
        <h3>Minting Ticket</h3>

        <S.TxStatusContainer>
          <TxProgress id={txId} status={txStatus} />
        </S.TxStatusContainer>
      </S.Container>
    </>
  );
}

export default PubMint;
