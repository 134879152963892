import { useState, useEffect } from "react";
import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { CHECK_COLLECTION } from "../../cadence/check-collection.script";
import { CREATE_COLLECTION } from "../../cadence/create-collection.tx";
import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";

import Button from "../../shared/Button";
import TxProgress from "../../shared/TxProgress";
import bloctoLogo from "../../assets/blocto-logo.png";
import Table from "./Table";

function PubPurchased({ order, user, connect }) {
  const [collection, setCollection] = useState(false);
  const [txId, setTxId] = useState("");
  const [txStatus, setTxStatus] = useState(10);
  const [txStatusCode, setTxStatusCode] = useState(0);

  /* statuses
    10  - idle
    9   - started

    0   - UNKNOWN
    1   - PENDING
    2   -
    3   - EXECUTED
    4   - SEALED
  */

  useEffect(() => {
    const init = async () => {
      const response = await fcl
        .send([
          fcl.script(CHECK_COLLECTION),
          fcl.args([fcl.arg(user.addr, t.Address)]),
        ])
        .then(fcl.decode);

      setCollection(response);
    };

    if (user.addr) {
      init();
    }
  }, [user]);

  useEffect(() => {
    if (txStatus === 4) {
      setCollection(true);
    }
  }, [txStatus]);

  const handleCreateCollection = async () => {
    setTxStatus(9);

    const transactionId = await fcl
      .send([
        fcl.transaction(CREATE_COLLECTION),
        fcl.proposer(fcl.authz),
        fcl.payer(fcl.authz),
        fcl.authorizations([fcl.authz]),
        fcl.limit(9999),
      ])
      .then(fcl.decode, (rejected) => setTxStatus(10));

    if (transactionId) {
      setTxId(transactionId);
      fcl.tx(transactionId).subscribe((res) => {
        setTxStatus(res.status);
        setTxStatusCode(res.statusCode);
        //console.log(res);
      });
    }
  };

  return (
    <>
      <S.Container>
        <h3>My Tickets</h3>

        {user.addr && collection ? (
          <S.BloctoContainer>
            <div style={{ width: "100%" }}>
              <p>
                <FontAwesomeIcon icon={faCircleCheck} /> Collection created.
                Your account is now ready to mint your tickets, please review
                your tickets below and click the mint button to continue.
              </p>

              <S.ButtonsContainer>
                <Button label="Main Menu" click="/" link={true} />
                <Button
                  label="View Minted Tickets"
                  click="/p/nfts"
                  link={true}
                />
              </S.ButtonsContainer>
            </div>
          </S.BloctoContainer>
        ) : user.addr ? (
          <S.BloctoContainer>
            <img src={bloctoLogo} alt="Blocto Logo" />

            <div>
              <p>
                <FontAwesomeIcon icon={faCircleCheck} /> Blocto wallet
                connected. Your wallet needs to have a collection created in
                order to store your minted tickets as NFTs, please click below
                to continue.
              </p>

              <S.ButtonsContainer one={true}>
                {txStatusCode === 0 && txStatus < 10 ? (
                  <TxProgress id={txId} status={txStatus} />
                ) : !collection ? (
                  <Button
                    label="Create Collection"
                    click={handleCreateCollection}
                  />
                ) : null}
              </S.ButtonsContainer>
            </div>
          </S.BloctoContainer>
        ) : (
          <S.BloctoContainer>
            <img src={bloctoLogo} alt="blocto Logo" />

            <div>
              <p>
                In order to mint your tickets as NFT’s, please first connect to
                your account using the Blocto wallet. If you have questions
                about this process, please read the instructions in our guide.
              </p>

              <S.ButtonsContainer>
                <Button label="Connect Wallet" click={connect} />
                <Button
                  label="View Instructions"
                  click="/p/instructions"
                  link={true}
                />
              </S.ButtonsContainer>
            </div>
          </S.BloctoContainer>
        )}

        {order.tickets.map((item) => {
          return (
            <Table
              data={item}
              user={user}
              collection={collection}
              key={item.orderId}
            />
          );
        })}
      </S.Container>
    </>
  );
}

export default PubPurchased;
