import styled from "styled-components";
import { Container, FormContainer, One, Two } from "../../styles/styled";

let S = {};

S.Container = styled(Container)``;
S.FormContainer = styled(FormContainer)``;
S.One = styled(One)``;
S.Two = styled(Two)``;

S.Terms = styled.div`
  width: 100%;
  height: 150px;
  overflow-y: scroll;
  margin-top: 25px;
  border: 2px solid #cccccc;
  border-radius: 4px;
  padding: 7.5px 15px;
  box-shadow: var(--boxshadow);
  background-color: #fff;

  & > h4 {
    font-size: 1rem;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--border);
  }

  & > p {
    font-size: 0.85rem;
    line-height: 20px;
  }
`;

export default S;
