import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-light-svg-icons";

function Home({ height = "130px" }) {
  return (
    <S.Container height={height}>
      <FontAwesomeIcon icon={faCircleNotch} size="3x" spin />
    </S.Container>
  );
}

export default Home;
