import styled from "styled-components";

let S = {};

S.Container = styled.div`
  margin-top: 15px;
  color: #fff;

  & > h3 {
    font-size: 1.25rem;
    line-height: 26px;
    text-align: center;
    padding: 0 15px 15px 15px;
    text-transform: uppercase;
  }

  & > p {
    padding: 0 15px 15px 15px;
  }
`;

S.ConnectContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  height: ${(props) => `calc(100vh - ${props.height})`};
  color: ${(props) => props.color || "inherit"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span {
    padding-top: 15px;
    font-size: 1.1rem;
  }

  & > button {
    margin-top: 25px;
    width: auto;
  }
`;

S.CarouselItemContainer = styled.div`
  width: ${(props) => props.width};
  height: 350px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  color: #000;
  position: relative;
`;

S.CarouselContainer = styled.div`
  margin: 0 auto;
  margin-top: 0;
  overflow: hidden;
  width: 350px;
`;

S.CarouselInner = styled.div`
  white-space: nowrap;
  transition: transform 0.3s;
  transform: ${(props) => props.transform};
`;

S.CirclesContainer = styled.div`
  margin: 0 auto;
  margin-top: 15px;
  width: 350px;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    & > button:nth-child(even) {
      margin: 0;
      margin-left: 15px;
      margin-right: 15px;
    }

    & > button:last-child {
      margin-right: 0;
    }
  }
`;

S.TicketContainer = styled.div`
  & > #half-circle {
    position: absolute;
    top: -42px;
    left: calc(50% - 32px);
  }

  & > #logo-container {
    position: absolute;
    top: 1px;
    left: 10px;
    width: 125px;
  }

  & > #day-container {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 125px;
    height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > span {
      font-weight: 500;
      text-transform: uppercase;
      font-size: 1rem;
    }
  }

  & > #img-container {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: 90px;
    overflow: hidden;

    & img {
      margin-top: -25px;
    }
  }

  & > #title-container {
    position: absolute;
    top: 150px;
    left: 0;
    width: 100%;
    padding: 5px;
    text-align: center;

    & span {
      display: block;
      line-height: 20px;
      font-size: 0.95rem;
      font-weight: 500;
    }

    & small {
      display: block;
      line-height: 18px;
      font-size: 0.85rem;
    }
  }

  & > #qr-container {
    position: absolute;
    top: 197px;
    left: 5px;

    & > img {
      width: 150px;
    }
  }

  & > #id-container {
    position: absolute;
    top: 197px;
    right: 0;
    width: 191px;
    height: 153px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > small {
        font-weight: 500;
        padding-top: 7.5px;
      }

      & > span {
        font-weight: 500;
        line-height: 28px;
        font-size: 1.35rem;
      }
    }

    & > div:first-child {
      margin-bottom: 2.5px;
    }

    & > div:nth-child(2) {
      margin-bottom: 12.5px;
    }
  }

  & > #add-container {
    position: absolute;
    top: 302px;
    right: 44px;
  }
`;

S.Grid = styled.div`
  padding: 0 15px 15px 15px;
  margin-top: 35px;
  display: grid;
  grid-template-columns: ${(props) => (props.one ? "1fr" : " 1fr 1fr")};
  grid-gap: 15px;
`;

export default S;
