import styled from "styled-components";

let S = {};

S.Container = styled.div`
  margin: 0 auto;
  width: 100%;
  height: ${(props) => `calc(100vh - ${props.height})`};
  color: ${(props) => props.color || "inherit"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span {
    padding-top: 15px;
    font-size: 1.1rem;
  }

  & > a {
    margin-top: 25px;
    width: auto;
  }
`;

export default S;
