import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, setValToLS } from "../../utils/index";
import S from "./styled";

import InputText from "../../shared/InputText";
import InputRadios from "../../shared/InputRadios";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

function AdmSignin() {
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});
  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();
  const formElement = useRef();

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL}/admSignin`);
    const formData = new FormData(formElement.current);
    formData.append("area", names.area ? names.area : "");

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setValToLS("adm", json.token, true);
        navigate("/a/scan");
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      <S.Container>
        <h3>Admin Entry</h3>

        <p>
          Use this form to enter the admin area and enable at entrance ticket
          scanning for your location.
        </p>
      </S.Container>

      <S.FormContainer>
        <form
          method="post"
          action="/"
          onSubmit={handleSubmit}
          ref={formElement}
        >
          <S.Two>
            <InputText
              name="email"
              label="Email Address"
              req={true}
              error={errors.includes("email") ? true : false}
              autocomplete="email"
            />

            <InputText
              type="password"
              name="pwd"
              label="Password"
              req={true}
              error={errors.includes("pwd") ? true : false}
              autocomplete="off"
            />
          </S.Two>

          <S.One className="radios">
            <InputRadios
              name="area"
              label="Indicate which gate this device will be scanning tickets at:"
              data={[
                {
                  value: "entry",
                  name: "Tournament Entry Gate",
                },
                {
                  value: "hospitality",
                  name: "Hospitality Area @ 17",
                },
              ]}
              checked={names.area}
              click={handleUpdate}
            />
          </S.One>

          <div className="msg-submit">
            {msg.type && <Msg data={msg} />}
            {msg.type === "working" ? null : <Submit name="Sign In" />}
          </div>
        </form>
      </S.FormContainer>
    </>
  );
}

export default AdmSignin;
