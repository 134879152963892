import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { CHECK_COLLECTION } from "../../cadence/check-collection.script";
import { CREATE_COLLECTION } from "../../cadence/create-collection.tx";
import { API_URL, getValFromLS, setValToLS } from "../../utils";
import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";

import InputRadios from "../../shared/InputRadios";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";
import Button from "../../shared/Button";
import TxProgress from "../../shared/TxProgress";
import bloctoLogo from "../../assets/blocto-logo.png";

function PubReceived({ ticket: ticketFromPub, user, connect }) {
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});
  const [ticket, setTicket] = useState(ticketFromPub);
  const [txId, setTxId] = useState("");
  const [txStatus, setTxStatus] = useState(10);
  const [txStatusCode, setTxStatusCode] = useState(0);
  const [step, setStep] = useState(1);

  /*
    1 - ticket pending, accept / decline response
    2 - ticket accepted, connect wallet
    3 - ticket accepted, create collection
    4 - ready to mint
  */

  const formElement = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (!ticketFromPub?.id && getValFromLS("ticket", true)) {
      setTicket(JSON.parse(getValFromLS("ticket", true)));
    } else if (ticketFromPub?.id) {
      setValToLS("ticket", JSON.stringify(ticketFromPub), true);
      setTicket(ticketFromPub);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const init = async () => {
      const response = await fcl
        .send([
          fcl.script(CHECK_COLLECTION),
          fcl.args([fcl.arg(user.addr, t.Address)]),
        ])
        .then(fcl.decode);

      if (response) {
        setStep(4);
      }
    };

    if (user.addr) {
      setStep(3);
      init();
    }
  }, [user]);

  useEffect(() => {
    if (txStatus === 4) {
      setStep(4);
    }
  }, [txStatus]);

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL}/received`);
    const formData = new FormData(formElement.current);
    formData.append("response", names.response || "");
    formData.append("ticket", JSON.stringify(ticket) || "{}");

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setStep(2);
      } else if (json && json.resp === 2) {
        setValToLS("ticket", null, true);
        navigate("alert", {
          state: {
            data: { type: "success", text: json.text },
            url: "/",
          },
        });
      } else {
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  const handleCreateCollection = async () => {
    setTxStatus(9);

    const transactionId = await fcl
      .send([
        fcl.transaction(CREATE_COLLECTION),
        fcl.proposer(fcl.authz),
        fcl.payer(fcl.authz),
        fcl.authorizations([fcl.authz]),
        fcl.limit(9999),
      ])
      .then(fcl.decode, (rejected) => setTxStatus(10));

    if (transactionId) {
      setTxId(transactionId);
      fcl.tx(transactionId).subscribe((res) => {
        setTxStatus(res.status);
        setTxStatusCode(res.statusCode);
        //console.log(res);
      });
    }
  };

  const handleMint = async (item) => {
    setValToLS("ticket", null, true);
    navigate("/p/mint", { state: { item } });
  };

  return (
    <>
      {step === 1 ? (
        <>
          <S.Container>
            <h3>Accept / Decline Ticket Transfer</h3>
            <p>
              You have been sent a ticket to the 2022 WWT Championship at
              Mayakoba from {ticket.email}. Please indicate your response below.
            </p>
            <ul>
              <li>
                If you choose to accept the ticket, please follow the next 3
                steps to create your wallet account and mint your ticket as an
                NFT (which is required for gate entry).
              </li>

              <li>
                If you choose to decline the ticket, {ticket.email} will be
                notified as such and no further action on your part is required.
              </li>
            </ul>
          </S.Container>

          <S.FormContainer>
            <form
              method="post"
              action="/"
              onSubmit={handleSubmit}
              ref={formElement}
            >
              <S.TicketContainer>
                <img
                  src={`${API_URL}/ticketImg?id=${ticket.category}`}
                  alt={ticket.title}
                />

                <p>
                  {ticket.title}
                  <br />
                  <small>{ticket.day}</small>
                </p>
              </S.TicketContainer>

              <S.One>
                <InputRadios
                  name="response"
                  label="Do you wish to accept or decline this ticket?"
                  data={[
                    {
                      value: "accept",
                      name: "Accept",
                    },
                    {
                      value: "decline",
                      name: "Decline",
                    },
                  ]}
                  checked={names.response}
                  click={handleUpdate}
                />
              </S.One>

              <div className="msg-submit">
                {msg.type && <Msg data={msg} />}
                {msg.type === "working" ? null : (
                  <Submit name="Submit Ticket Transfer Response" />
                )}
              </div>
            </form>
          </S.FormContainer>
        </>
      ) : (
        <>
          <S.Container>
            <h3>Steps to Mint NFT Ticket</h3>

            <ol>
              <li className={step > 2 ? "completed" : ""}>
                Connect Blocto Wallet on the Flow Blockchain
                <div className={step > 2 ? "hidden" : ""}>
                  <S.BloctoContainer>
                    <img src={bloctoLogo} alt="blocto Logo" />

                    <div>
                      <p>
                        In order to mint your ticket as an NFT, please first
                        connect to your account using the Blocto wallet.
                      </p>

                      <S.ButtonsContainer one={true}>
                        <Button label="Connect Wallet" click={connect} />
                      </S.ButtonsContainer>
                    </div>
                  </S.BloctoContainer>
                </div>
              </li>

              <li
                className={
                  step > 3 ? "completed" : step === 2 ? "notready" : ""
                }
              >
                Create an Empty Collection
                <div className={step === 2 || step > 3 ? "hidden" : ""}>
                  <S.BloctoContainer noMargin={true}>
                    <div>
                      <p>
                        <FontAwesomeIcon icon={faCircleCheck} /> Blocto wallet
                        connected. Your wallet needs to have a collection
                        created in order to store your minted ticket as an NFT,
                        please click below to continue.
                      </p>

                      <S.ButtonsContainer one={true}>
                        {txStatusCode === 0 && txStatus < 10 ? (
                          <TxProgress id={txId} status={txStatus} />
                        ) : step === 3 ? (
                          <Button
                            label="Create Collection"
                            click={handleCreateCollection}
                          />
                        ) : null}
                      </S.ButtonsContainer>
                    </div>
                  </S.BloctoContainer>
                </div>
              </li>

              <li
                className={
                  step > 4
                    ? "completed"
                    : step === 2 || step === 3
                    ? "notready"
                    : ""
                }
              >
                Ready to Mint your NFT Ticket
                <div className={step === 2 || step === 3 ? "hidden" : ""}>
                  <S.BloctoContainer noMargin={true}>
                    <div>
                      <p>
                        <FontAwesomeIcon icon={faCircleCheck} /> Collection
                        created. Your account is now ready to mint your ticket,
                        please click the mint button to continue.
                      </p>

                      <S.ButtonsContainer one={true}>
                        <Button
                          label="Mint Ticket to My Account"
                          click={() => handleMint(ticket)}
                        />
                      </S.ButtonsContainer>
                    </div>
                  </S.BloctoContainer>
                </div>
              </li>
            </ol>
          </S.Container>
        </>
      )}
    </>
  );
}

export default PubReceived;
