import styled from "styled-components";
import { Container } from "../../styles/styled";

let S = {};

S.Container = styled(Container)``;

S.BloctoContainer = styled.div`
  padding: 0px 25px 35px 25px;
  display: flex;
  align-items: center;

  & > img {
    width: 75px;
  }

  & > div {
    margin-left: 20px;
  }
`;

S.ButtonsContainer = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: ${(props) => (props.one ? "1fr" : "  1fr 1fr")};
  grid-gap: 25px;

  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
`;

S.Table = styled.table`
  margin-bottom: 25px;
  color: #000;

  & th,
  td {
    border: none;
  }
`;

S.Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  color: #fff;
  justify-items: center;

  & > div {
    width: 100%;
    border: 1px solid var(--border);
    padding: 15px;
    display: flex;

    @media screen and (max-width: 650px) {
      flex-direction: column;
      align-items: center;
    }

    & > img {
      width: 250px;
    }

    & > div {
      width: 100%;
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 650px) {
        margin-left: 0;
      }
    }

    & > div p {
      text-align: center;
      font-size: 1.15rem;
      font-weight: 500;
      line-height: 30px;

      @media screen and (max-width: 650px) {
        margin-top: 10px;
      }
    }

    & > div small {
      display: block;
      margin-bottom: 15px;
    }

    & > div button {
      width: 100%;
    }

    & > div button:last-child {
      margin-top: 15px;
    }
  }
`;

S.ButtonsContainer2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 7.5px;
`;

S.StatusMsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--notice);
  margin-bottom: 20%;

  @media screen and (max-width: 650px) {
    margin-bottom: 0;
  }

  & > span {
    display: block;
    font-size: 1.1rem;
    margin-top: 7.5px;
  }
`;

export default S;
