export const GET_NFT_META = `
  import NFTTickets from 0xNFTTickets

  pub fun main(addr: Address, id: UInt64): String {
    let collectionRef = getAccount(addr)
      .getCapability(/public/TixNFTCollection)
      .borrow<&NFTTickets.Collection{NFTTickets.CollectionPublic}>()
      ?? panic("The address does not have a public collection.")

    let nftRef: &NFTTickets.NFT = collectionRef.borrowEntireNFT(id: id)
    
    return nftRef.code
  }
`;
