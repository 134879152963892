import styled from "styled-components";

let S = {};

S.Container = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.type === "success"
      ? "var(--bgsuccess)"
      : props.type === "error"
      ? "var(--bgerror)"
      : "var(--bgnotice)"};
  color: ${(props) =>
    props.type === "success"
      ? "var(--success)"
      : props.type === "error"
      ? "var(--error)"
      : "var(--notice)"};
  border: 1px solid var(--notice);
  border-color: ${(props) =>
    props.type === "success"
      ? "var(--success)"
      : props.type === "error"
      ? "var(--error)"
      : "var(--notice)"};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 15px;

  & > span {
    display: block;
    padding-left: 10px;
    line-height: 20px;
    font-weight: 500;
    font-size: 1.05rem;
  }
`;

export default S;
