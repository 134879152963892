import styled from "styled-components";

let S = {};

S.Container = styled.div`
  margin-top: 15px;
  display: flex;
  align-self: flex-start;
  padding-left: 0 !important;

  & a {
    display: inline-block;
    margin-top: 15px;
    color: #fff;
    font-size: 0.95rem;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    transition: all 0.3s;
    text-transform: uppercase;

    & > span {
      padding-left: 7.5px;
    }

    &:hover {
      color: var(--bglightblue);
    }
  }
`;

export default S;
