import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faCircleNotch,
  faRightFromBracket,
} from "@fortawesome/pro-light-svg-icons";

import Button from "../Button";

function WalletStatus({ user, working, disconnect }) {
  return (
    <S.Container className={user?.addr ? "" : "working"}>
      <div>
        <FontAwesomeIcon icon={faBriefcase} size="lg" />
        {user?.addr ? <span>{user.addr}</span> : <span>Not Connected</span>}
      </div>

      {working && <FontAwesomeIcon icon={faCircleNotch} size="lg" spin />}
      {user.addr && (
        <Button
          label=""
          click={disconnect}
          naked={true}
          icon={faRightFromBracket}
        />
      )}
    </S.Container>
  );
}

export default WalletStatus;
