function Bell({ sound }) {
  const audio = new Audio(`${process.env.PUBLIC_URL}/success.wav`);
  audio.preload = "auto";
  //const errorBell = new Audio(`${process.env.PUBLIC_URL}/error.wav`);

  if (sound === "initialize") {
    const playPromise = audio.play();

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          audio.pause();
        })
        .catch((error) => {});
    }
  } else if (sound === "success" || sound === "error") {
    audio.src = `${process.env.PUBLIC_URL}/${sound}.wav`;
    audio.play();
  }

  return <div />;
}

export default Bell;
