function Div({ hdg, descr, id }) {
  return (
    <div>
      TX Status: <i>{hdg}</i>
      <br />
      <small>{descr}</small>
      {id && (
        <p>
          <a
            href={`https://testnet.flowscan.org/transaction/${id}`}
            target="_blank"
            rel="noreferrer"
          >
            {`View on Flowscan ${id.slice(0, 7)}...`}
          </a>
        </p>
      )}
    </div>
  );
}

export default Div;
