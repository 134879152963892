import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";

function StatusMsg({ text }) {
  return (
    <S.StatusMsgContainer>
      <FontAwesomeIcon icon={faCircleExclamation} size="2x" />
      <span>{text}</span>
    </S.StatusMsgContainer>
  );
}

export default StatusMsg;
