import styled from "styled-components";

let S = {};

S.Container = styled.div`
  & > label {
    color: #fff;
    display: block;
    padding-bottom: 3.5px;
    font-weight: 500;
  }

  & > input[type="text"],
  & > input[type="password"] {
    margin: 0;
    width: 100%;
    border: 2px solid #cccccc;
    border-radius: 4px;
    padding: 4px 7.5px 2.5px 7.5px;
    box-shadow: var(--boxshadow);
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    font-family: inherit;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 28px;
    font-weight: 500;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      border-color: var(--bglightblue);
    }

    &.error-border {
      border-color: var(--error);
    }
  }

  & > input[type="password"] {
    font-family: verdana;
    letter-spacing: 0.125em;
  }
`;

export default S;
