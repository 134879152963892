import styled from "styled-components";
import { Link } from "react-router-dom";

let S = {};

S.Button = styled.button`
  width: 100%;
  display: inline-block;
  background-color: var(--bglightblue);
  border: 2px solid var(--border);
  color: #fff;
  font-size: 0.95rem;
  font-weight: 500;
  border-radius: 4px;
  padding: 6.4px 15px 5px 15px;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  transition: all 0.3s;
  text-transform: uppercase;

  &:hover {
    background-color: var(--bgblue);
  }

  &.naked {
    width: auto;
    color: inherit;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  button > div {
    display: flex;
    align-items: center;
  }

  button > div > span {
    padding-left: 7.5px;
    font-family: inherit;
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
`;

S.Link = styled(Link)`
  width: 100%;
  display: inline-block;
  background-color: var(--bglightblue);
  border: 2px solid var(--border);
  color: #fff;
  font-size: 0.95rem;
  font-weight: 500;
  border-radius: 4px;
  padding: 6.4px 15px 5px 15px;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  transition: all 0.3s;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--bgblue);
  }
`;

export default S;
