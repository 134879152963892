import S from "./styled";

function InputText({
  type = "text",
  name,
  label,
  req,
  error,
  autocomplete = "off",
}) {
  return (
    <S.Container>
      <label htmlFor={name}>
        {label} {req && <span className="error">*</span>}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        defaultValue=""
        autoComplete={autocomplete}
        className={error ? "error-border" : ""}
      />
    </S.Container>
  );
}

export default InputText;
