import styled from "styled-components";

const S = {};

S.Container = styled.div`
  height: 45px;
  padding: 0 10px 0 15px;
  background-color: var(--bggrey);
  border-top: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.working {
    color: var(--working);
  }

  & > div {
    display: flex;
    align-items: center;
  }

  & .hdr-btns > button:last-child {
    margin-left: 7.5px;
  }

  & > div > span {
    font-size: 0.9rem;
    padding-top: 1.5px;
    padding-left: 7.5px;
  }
`;

export default S;
