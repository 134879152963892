import styled from "styled-components";

let S = {};

S.Content = styled.div`
  padding: 0;
  color: #fff;

  & > p.lead {
    padding: 15px 15px 12.5px 15px;
    text-align: center;
    margin-bottom: 0;
    font-size: 1.1rem;
    font-weight: 500;
    border-bottom: 1px solid #fff;
    background: var(--bglightblue);
  }

  & > .notice-container {
    padding: 0 15px;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  & > ul {
    margin: 0;
    margin-left: 30px;
    padding: 0;
    font-size: 0.95rem;
    padding: 0 15px;
  }

  & > ul > li {
    line-height: 22px;
  }

  & > ul > li {
    padding-top: 15px;
    padding-bottom: 5px;
  }

  & .grid {
    padding: 0 15px;
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
`;

export default S;
