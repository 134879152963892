import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/index";

import Loading from "../../shared/Loading";

function PubXferring({ setTicket }) {
  const rrParams = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      const url = new URL(`${API_URL}/xferringInit`);
      const params = { q: rrParams.str };
      url.search = new URLSearchParams(params);

      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
        });
        const json = await response.json();
        if (json && json.resp === 1) {
          delete json.resp;

          setTicket(json);
          navigate("/p/received");
        } else {
          navigate("/alert", {
            state: { data: { type: "error", text: json.text }, url: "/" },
          });
        }
      } catch (error) {
        navigate("/alert", {
          state: {
            data: { type: "error", text: "An error has occurredddd." },
            url: "/",
          },
        });
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading height="175px" />;
}

export default PubXferring;
