import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import S from "./styled";

function InputRadios({ label, name, data, checked, click }) {
  return (
    <>
      <S.P>{label}</S.P>

      {data.map((item, index) => {
        return (
          <S.Button
            type="button"
            onClick={() => click(name, item.value)}
            className="naked"
            key={index}
          >
            <div>
              <FontAwesomeIcon
                icon={item.value === checked ? faCircleCheck : faCircle}
                size="lg"
                style={{ fontSize: "1.2rem" }}
              />
              <span>{item.name}</span>
            </div>
          </S.Button>
        );
      })}
    </>
  );
}

export default InputRadios;
