import { Routes, Route } from "react-router-dom";

import Logo from "./shared/Logo";
import Footer from "./shared/Footer";

import Home from "./pages/Home";
import Alert from "./pages/Alert";

import Pub from "./Pub";
import Adm from "./Adm";

function App() {
  return (
    <>
      <Logo />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/alert" element={<Alert />} />
        <Route path="/p/*" element={<Pub />} />
        <Route path="/a/*" element={<Adm />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
