import styled from "styled-components";

const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;

  & > span {
    padding-top: 10px;
    font-size: 1rem;
    line-height: 22px;
  }
`;

export default S;
