import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, getValFromLS } from "../../utils/index";
import useQRCodeScan from "../../utils/useQRCodeScan";
import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";

import Button from "../../shared/Button";
import Notice from "../../shared/Notice";
import Loading from "../../shared/Loading";
import InputText from "../../shared/InputText";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";
import Bell from "../../shared/Bell";

function getArea(area) {
  if (area === "entry") {
    return "tournament entry gate";
  }
}

function AdmScan({ setUser, setWorking }) {
  const [names, setNames] = useState({
    showForm: false,
  });
  const [msg, setMsg] = useState({});
  const [errors, setErrors] = useState([]);
  const [notice, setNotice] = useState({});
  const [sound, setSound] = useState("");

  const { startQrCode, decodedQRData, stopQrCode } = useQRCodeScan({
    qrcodeMountNodeID: "qrcodemountnode",
  });

  const formElement = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      setWorking(true);

      const url = new URL(`${API_URL}/auth/scanInit`);
      const params = { token: getValFromLS("adm", true) || "" };
      url.search = new URLSearchParams(params);

      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
        });
        const json = await response.json();
        if (json && json.resp === 1) {
          setNames((names) => ({
            ...names,
            email: json.email,
            area: json.area,
          }));

          setWorking(false);
          setUser({
            email: json.email,
            area: json.area,
          });
        } else {
          navigate("/alert", {
            state: {
              data: { type: "error", text: json.text },
              url: "/a/signin",
            },
          });
        }
      } catch (error) {
        navigate("/alert", {
          state: {
            data: { type: "error", text: "An error has occurred." },
            url: "/a/signin",
          },
        });
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (decodedQRData.isScanSuccess && decodedQRData.data) {
      setNotice({
        type: "loading",
        text: "",
      });

      const array = decodedQRData.data.split("|");
      const payload = {
        type: "scanned",
        code: array[0],
        addr: array[1],
      };

      handlePost(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedQRData]);

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMsg({
      type: "working",
      text: "",
    });
    setErrors([]);

    const formData = new FormData(formElement.current);

    const payload = {
      type: "entered",
      code: formData.get("code") || "",
      addr: "",
    };

    handlePost(payload);
  };

  const handlePost = async (payload) => {
    const url = new URL(`${API_URL}/auth/scan`);
    const formData = new FormData();
    formData.append("token", getValFromLS("adm", true));
    formData.append("email", names.email ? names.email : "");
    formData.append("area", names.area ? names.area : "");
    formData.append("type", payload.type);
    formData.append("code", payload.code);
    formData.append("addr", payload.addr);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        if (sound !== "success") {
          setSound("success");
        }
        handleSuccess(json.code);
      } else if (json && json.resp === 0 && payload.type === "entered") {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      } else {
        if (sound !== "error") {
          setSound("error");
        }
        handleError(json.text);
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  const handleSuccess = (code) => {
    setMsg({});
    setNames((names) => ({ ...names, showForm: false }));
    setNotice({
      type: "success",
      text: `Ticket ${code} has been successfully redeemed.`,
    });
    formElement.current.code.value = "";
    //setSound("");
  };

  const handleError = (text) => {
    setMsg({});
    setNames((names) => ({ ...names, showForm: false }));
    setNotice({
      type: "error",
      text,
    });
    formElement.current.code.value = "";
    //setSound("");
  };

  return (
    <>
      <S.Container>
        <h3>Ready to Scan Tickets</h3>

        <p>
          <FontAwesomeIcon icon={faCircleCheck} /> This device has been
          initialized to scan tickets at the {getArea(names.area)}.
        </p>
      </S.Container>

      {notice?.type && (
        <div style={{ padding: "15px 25px 25px 25px" }}>
          {notice.type === "loading" ? (
            <Loading />
          ) : (
            <Notice type={notice.type} text={notice.text} />
          )}
        </div>
      )}

      <Bell sound={sound} />

      <div style={{ padding: "0px 25px 25px 25px" }}>
        <S.ButtonsContainer>
          {decodedQRData?.isScanning ? (
            <Button
              label="Stop Scanning"
              click={() => {
                stopQrCode();
              }}
            />
          ) : (
            <Button
              label="Start Scanning"
              click={() => {
                setNotice({});
                handleUpdate("showForm", false);
                if (sound === "") {
                  setSound("initialize");
                } else {
                  setSound("initialized");
                }
                startQrCode();
              }}
            />
          )}
          <Button
            label="Manually Enter Ticket"
            click={() => {
              setNotice({});
              setNames((names) => ({ ...names, showForm: true }));
              if (sound === "") {
                setSound("initialize");
              } else {
                setSound("initialized");
              }
            }}
          />
        </S.ButtonsContainer>
      </div>

      <div id="qrcodemountnode"></div>

      <S.FormContainer className={names.showForm ? "" : "hidden"}>
        <form
          method="post"
          action="/"
          onSubmit={handleSubmit}
          ref={formElement}
        >
          <S.Two>
            <InputText
              name="code"
              label="Ticket Code"
              req={true}
              error={errors.includes("code") ? true : false}
              autocomplete="off"
            />

            <div className="empty" />
          </S.Two>

          <div className="msg-submit">
            {msg.type && <Msg data={msg} />}
            {msg.type === "working" ? null : (
              <Submit name="Submit Ticket Code" />
            )}
          </div>
        </form>
      </S.FormContainer>

      {/*<p>{JSON.stringify(decodedQRData)}</p>*/}
    </>
  );
}

export default AdmScan;
