import { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { setValToLS } from "./utils";

import AdminStatus from "./shared/AdminStatus";

import AdmSignin from "./pages/AdmSignin";
import AdmScan from "./pages/AdmScan";

function Adm() {
  const [user, setUser] = useState({});
  const [working, setWorking] = useState(false);

  const navigate = useNavigate();

  const handleSignout = () => {
    setUser({});
    setValToLS("adm", null, true);
    navigate("/a/signin");
  };

  return (
    <>
      <AdminStatus user={user} working={working} signout={handleSignout} />
      <Routes>
        <Route path="/signin" element={<AdmSignin />} />
        <Route
          path="/scan"
          element={<AdmScan setUser={setUser} setWorking={setWorking} />}
        />
      </Routes>
    </>
  );
}

export default Adm;
