export const CHECK_COLLECTION = `
  import NFTTickets from 0xNFTTickets

  pub fun main(addr: Address): Bool {
    let check = getAccount(addr)
      .getCapability<&NFTTickets.Collection{NFTTickets.CollectionPublic}>(/public/TixNFTCollection)
      .check()
      
    return check
  }
`;
