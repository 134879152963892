import S from "./styled";

export default function Logo() {
  return (
    <S.LogoContainer>
      <a href="https://wwtchampionshipatmayakoba.com">
        <img
          src="https://wwtchampionshipatmayakoba.com/assets/images/logo-wwt-mayakoba.svg"
          alt="World Wide Technology Championship at Mayakoba"
        />
      </a>
    </S.LogoContainer>
  );
}
