import { useState, createRef, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { API_URL, RECAPTCHA_KEY } from "../../utils/index";
import S from "./styled";

import InputText from "../../shared/InputText";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";
import BackButton from "../../shared/BackButton";

function PubLocate() {
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});
  const [errors, setErrors] = useState([]);

  const recaptchaRef = createRef();
  const formElement = useRef();
  const navigate = useNavigate();

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMsg({
      type: "working",
      text: "",
    });

    let recaptcha = names.recaptcha
      ? names.recaptcha
      : await recaptchaRef.current.executeAsync();

    const url = new URL(`${API_URL}/locatePurchased`);
    const formData = new FormData(formElement.current);
    //const email = formData.get("email");
    //const last4 = formData.get("last4");
    formData.append("recaptcha", recaptcha);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        navigate(`/p/locate/${json.str}`);
      } else {
        if (json.fields[0] === "recaptcha") {
          handleUpdate("recaptcha", "");
          recaptchaRef.current.reset();
        } else {
          handleUpdate("recaptcha", recaptcha);
        }
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      <S.Container>
        <h3>Locate Ticket Purchase</h3>
        <p>
          The ticketing system for this year’s event is paperless and uses
          blockchain technology to insure the security and validity of your
          entry ticket.
        </p>

        <ul>
          <li>
            If you are ready to mint your entry tickets, but maybe have
            misplaced or didn’t receive your ticket order confirmation email,
            use the form below with your email address and the last 4 digits of
            the card used for your ticket purchase to locate your order.
          </li>
        </ul>
      </S.Container>

      <S.FormContainer>
        <form
          method="post"
          action="/"
          onSubmit={handleSubmit}
          ref={formElement}
        >
          <S.One style={{ marginBottom: "20px" }}>
            <InputText
              name="email"
              label="Email Address"
              req={true}
              error={errors.includes("email") ? true : false}
              autocomplete="email"
            />
          </S.One>

          <S.One style={{ marginBottom: "25px" }}>
            <InputText
              name="last4"
              label="Last 4 of Credit Card"
              req={true}
              error={errors.includes("last4") ? true : false}
            />
          </S.One>

          <S.One>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={RECAPTCHA_KEY}
              badge="inline"
            />
          </S.One>

          <div className="msg-submit">
            {msg.type && <Msg data={msg} />}
            {msg.type === "working" ? null : <Submit name="Retrieve Order" />}
          </div>

          <BackButton label="Back to Main Menu" click="/" />
        </form>
      </S.FormContainer>
    </>
  );
}

export default PubLocate;
