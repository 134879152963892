import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faCircleCheck,
  faCircleX,
} from "@fortawesome/pro-light-svg-icons";

function Notice({ type = "notice", text }) {
  return (
    <S.Container type={type}>
      <FontAwesomeIcon
        icon={
          type === "success"
            ? faCircleCheck
            : type === "error"
            ? faCircleX
            : faCircleExclamation
        }
        size="lg"
      />
      <span>{text}</span>
    </S.Container>
  );
}

export default Notice;
