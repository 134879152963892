import styled from "styled-components";
import { Container, FormContainer, One } from "../../styles/styled";

let S = {};

S.Container = styled(Container)`
  & > ul > li:first-child {
    margin-bottom: 15px;
  }

  & > ol {
    margin-top: 7.5px;
    border: 1px solid var(--border);
    margin-right: 25px;
    padding-top: 15px;

    & li {
      font-size: 1.1rem;
      padding-bottom: 20px;
      margin-left: 25px;

      &.completed {
        color: var(--successLighter);
      }

      &.notready {
        color: var(--working);
      }

      & > div.hidden {
        display: none;
      }
    }
  }
`;

S.FormContainer = styled(FormContainer)``;
S.One = styled(One)``;

S.TicketContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #fff;

  & > img {
    width: 150px;
  }

  & > p {
    font-size: 1.1rem;
    font-weight: 500;
    margin-left: 15px;

    & > small {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }
`;

S.BloctoContainer = styled.div`
  padding: 15px 7.5px 5px 0;
  display: flex;
  align-items: center;

  & > img {
    width: 75px;
  }

  & > div {
    margin-left: ${(props) => (props.noMargin ? "0" : "20px")};

    & > p {
      font-weight: 300;
      font-size: 1rem;
    }
  }
`;

S.ButtonsContainer = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: ${(props) => (props.one ? "1fr" : "1fr 1fr")};
  grid-gap: 25px;

  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
`;

export default S;
