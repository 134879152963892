import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faCircleCheck,
  faWatch,
} from "@fortawesome/pro-light-svg-icons";

import Div from "./Div";

/* https://docs.onflow.org/fcl/reference/api/#transaction-statuses
 * -1 No Active Transaction
 * 0  Unknown
 * 1  Transaction Pending - Awaiting Finalization
 * 2  Transaction Finalized - Awaiting Execution
 * 3  Transaction Executed - Awaiting Sealing
 * 4  Transaction Sealed - Transaction Complete. At this point the transaction result has been committed to the blockchain.
 * 5  Transaction Expired
 */

function TxProgress({ id, status }) {
  return (
    <S.Container>
      {status === 9 ? (
        <div>
          <FontAwesomeIcon icon={faCircleNotch} size="2x" spin />
          <Div
            hdg="Initializing..."
            descr="Waiting for transaction approval."
            id={id}
          />
        </div>
      ) : status < 2 ? (
        <div>
          <FontAwesomeIcon icon={faCircleNotch} size="2x" spin />
          <Div
            hdg="Pending..."
            descr="The transaction is currently awaiting finalization."
            id={id}
          />
        </div>
      ) : status === 2 ? (
        <div>
          <FontAwesomeIcon icon={faCircleNotch} size="2x" spin />
          <Div
            hdg="Finalized..."
            descr="The transaction is currently awaiting execution."
            id={id}
          />
        </div>
      ) : status === 3 ? (
        <div>
          <FontAwesomeIcon icon={faWatch} size="2x" />
          <Div
            hdg="Executed..."
            descr="The transaction is currently awaiting sealing."
            id={id}
          />
        </div>
      ) : status === 4 ? (
        <div>
          <FontAwesomeIcon icon={faCircleCheck} size="2x" />
          <Div
            hdg="Sealed!"
            descr="Transaction complete and result has been committed to the blockchain."
            id={id}
          />
        </div>
      ) : (
        <Div hdg="..." descr="" id="" />
      )}
    </S.Container>
  );
}

export default TxProgress;
