import styled from "styled-components";

let S = {};

S.Container = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 0.9rem;
  padding: 15px;
  color: #fff;
  border-radius: 4px;

  & > div {
    display: flex;
    align-items: center;

    & > div {
      margin-left: 12.5px;
    }
  }

  & p {
    margin-top: 5px;
    margin-bottom: 0;
  }
`;

export default S;
