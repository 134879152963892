export const GET_NFT_IDS = `
  import NFTTickets from 0xNFTTickets

  pub fun main(addr: Address): [UInt64] {
    let collectionRef = getAccount(addr)
      .getCapability(/public/TixNFTCollection)
      .borrow<&NFTTickets.Collection{NFTTickets.CollectionPublic}>()
      ?? panic("The address does not have a public collection.")
    
    return collectionRef.getIDs()
  }
`;
