import { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL, getValFromLS } from "../../utils/index";
import S from "./styled";

import InputText from "../../shared/InputText";
import InputCheckbox from "../../shared/InputCheckbox";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

function PubXfer({ setOrder }) {
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});
  const [errors, setErrors] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const formElement = useRef();

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMsg({
      type: "working",
      text: "",
    });

    const url = new URL(`${API_URL}/xfer`);
    const formData = new FormData(formElement.current);
    formData.append("accept", names.accept ? "1" : "0");
    formData.append("ticket", JSON.stringify(location.state.item) || "{}");

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        navigate("/alert", {
          state: {
            data: { type: "success", text: json.text },
            url: getValFromLS("str", true)
              ? `/p/locate/${getValFromLS("str", true)}`
              : `/`,
          },
        });
      } else {
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      <S.Container>
        <h3>Transfer Ticket</h3>
        <p>
          Use this form to transfer a ticket to someone else. Please note that
          once the ticket transfer invitation is sent, the ticket will no longer
          be available to mint in your account unless the recipient declines the
          request.
        </p>
      </S.Container>

      <S.FormContainer>
        <form
          method="post"
          action="/"
          onSubmit={handleSubmit}
          ref={formElement}
        >
          <S.One>
            <InputText
              name="name"
              label="Recipient’s Name"
              req={true}
              error={errors.includes("name") ? true : false}
              autocomplete="name"
            />
          </S.One>

          <S.Two>
            <InputText
              name="email"
              label="Email Address"
              req={true}
              error={errors.includes("email") ? true : false}
              autocomplete="email"
            />

            <InputText
              name="confirm"
              label="Confirm Email"
              req={true}
              error={errors.includes("confirm") ? true : false}
              autocomplete="off"
            />
          </S.Two>

          <S.Terms>
            <h4>Terms of Ticket Transfer</h4>
            <p>
              Et harum quidem rerum facilis est et expedita distinctio. Nam
              libero tempore, cum soluta nobis est eligendi optio cumque nihil
              impedit quo minus id quod maxime placeat facere possimus, omnis
              voluptas assumenda est, omnis dolor repellendus. Temporibus autem
              quibusdam et aut officiis debitis aut rerum necessitatibus saepe
              eveniet ut et voluptates repudiandae sint et molestiae non
              recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut
              aut reiciendis voluptatibus maiores alias consequatur aut
              perferendis doloribus asperiores repellat.
            </p>
          </S.Terms>

          <S.One className="checkbox">
            <InputCheckbox
              name="accept"
              label="Check to accept terms of the ticket transfer"
              checked={names.accept ? true : false}
              click={handleUpdate}
            />
          </S.One>

          <div className="msg-submit">
            {msg.type && <Msg data={msg} />}
            {msg.type === "working" ? null : (
              <Submit name="Send Ticket Transfer Invitation" />
            )}
          </div>
        </form>
      </S.FormContainer>
    </>
  );
}

export default PubXfer;
