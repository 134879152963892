import styled from "styled-components";

let S = {};

S.P = styled.p`
  color: #fff;
  font-size: 1.05rem;
  margin-top: 15px;
  margin-bottom: 10px;
`;

S.Button = styled.button`
  display: inline-block;
  background-color: var(--bglightblue);
  border: 2px solid var(--border);
  color: #fff;
  font-size: 0.95rem;
  font-weight: 500;
  border-radius: 4px;
  padding: 6.5px 15px 5px 15px;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  width: auto;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 0.8rem;

  & > div {
    display: flex;
    align-items: center;
    margin-bottom: 7.5px;
    margin-left: 15px;
  }

  & > div > span {
    padding-left: 7.5px;
    font-family: inherit;
    font-size: 0.95rem;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
`;

export default S;
