import styled from "styled-components";
import { Container, FormContainer, One, Two } from "../../styles/styled";

let S = {};

S.Container = styled(Container)``;
S.FormContainer = styled(FormContainer)``;
S.One = styled(One)``;
S.Two = styled(Two)``;

export default S;
