import styled from "styled-components";
import { Container, FormContainer, One } from "../../styles/styled";

let S = {};

S.Container = styled(Container)``;
S.FormContainer = styled(FormContainer)`
  margin-top: 15px;

  & > form {
    padding-bottom: 12.5px;
  }
`;

S.One = styled(One)``;

export default S;
